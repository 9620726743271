import { graphql, useStaticQuery } from 'gatsby'

import React from 'react'
import footerStyle from '../styles/footer.module.scss'

const Footer = () => {

    const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)
    
    return (
        <footer className={`${footerStyle.footer}`}>
          <div className={`${footerStyle.footerItem}`}>
            <p className={`${footerStyle.footerContent}`}>Created by {data.site.siteMetadata.author} © {new Date().getFullYear()}</p>
          </div>
        </footer>
    )
}

export default Footer